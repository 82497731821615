<template>
  <div class="language">
    <div class="market_top">
      <div class="left">
        <div class="pic">
          <img src="../../assets/img/home_tatol_icon.png" alt />
        </div>
        <p class="title">BTCBAY</p>
      </div>
      <div @click="goback" class="system-closeb">
        <img src="../../assets/img/system-closeb.png" alt="" />
      </div>
    </div>
    <div class="language-item">
      <p
        @click="languageChange(item)"
        v-for="(item, index) in nationList"
        :key="index"
        class="item"
        :class="$i18n.locale == item.value ? 'select' : ''"
      >
        {{ item.label }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      currentNation: '',
      nationList: [
        {
          label: 'English',
          value: 'EN'
        },
        {
          label: '简体中文',
          value: 'ZH'
        },
        // {
        //   label: '한국어',
        //   value: 'KO'
        // },
        // {
        //   label: '日本語',
        //   value: 'JA'
        // }
      ]
    };
  },
  created() {},
  mounted() {
    if (
      navigator.language === 'zh-CN' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = '简体中文';
      this.$i18n.locale = 'ZH';
      localStorage.setItem('currentNation', 'ZH');
    } else if (
      navigator.language === 'en' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = 'English';
      this.$i18n.locale = 'EN';
      localStorage.setItem('currentNation', 'EN');
    } else if (
      navigator.language === 'ja' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = '日本語';
      this.$i18n.locale = 'JA';
      localStorage.setItem('currentNation', 'JA');
    } else if (
      navigator.language === 'ko' &&
      !localStorage.getItem('currentNation')
    ) {
      this.currentNation = '한국어';
      this.$i18n.locale = 'KO';
      localStorage.setItem('currentNation', 'KO');
    }
  },
  methods: {
    goback() {
      this.$router.push('/market');
    },
    languageChange(item) {
      this.$i18n.locale = item.value;
      localStorage.setItem('currentNation', item.value);
      this.$router.go(-1)
      for (let n of this.nationList) {
        if (n.value == item.value) {
          this.currentNation = n.label;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.language {
  .market_top {
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    padding: 0 28px 0 32px;
    // width: 100%;
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .pic {
        // padding-right: 20px;
        img {
          width: 56px;
          height: 56px;
          margin-right: 20px;
        }
        p {
          font-size: 38px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #333333;
          line-height: 53px;
        }
      }
    }

    .system-closeb {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .language-item {
    .item {
      height: 89px;
      font-size: 28px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #999999;
      line-height: 89px;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
      text-indent: 50px;
    }
    .select {
      color: #007aff;
    }
  }
}
</style>
